<template>
  <div>
    <div class="promotion__header ar-h1">
      Продвижение
    </div>
    <div v-if="loading" id="loader" style="min-height:71.1111111111111112em ; width:100%; " />
    <template v-else>
      <template v-if="releases.length === 0">
        <el-empty style="min-height:61.1111111111111112em" description="нет данных" />
      </template>
      <template v-else>
        <el-row :gutter="20">
          <div v-for="release in releases" :key="release.id" class="promotion-card" @click="$router.push(`/promo-company/${release.id }`)">
            <div class="promotion-card__wrapper  ar-card  pa4">
              <div style="position: absolute; right:1.1111111111111112em; top:1.1111111111111112em; display:flex">
                <el-icon v-if="release.release" style="transform: rotate(270deg);" size="2em" @click.stop="copy(release.release)">
                  <Link color="#3290FF" />
                </el-icon>
              </div>
              <el-image :src="release.release.imageThumbPath || release.release.imagePath" class="promotion-image">
                <template #error>
                  <div class="image-slot">
                    <el-icon size="50">
                      <Catalog />
                    </el-icon>
                  </div>
                </template>
              </el-image>
              <div class="promotion-card__content">
                <div class="promotion-card__name mb2">
                  {{ release.release.name }}
                </div>
                <div class="promotion-card__artist mb2">
                  {{ release.release.headArtists.join(', ') }}
                </div>
                <div class="promotion-card__type">
                  {{ release.release.type }}
                </div>
              </div>
            </div>
          </div>
        </el-row>
      </template>
    </template>
    <div style="display: flex; justify-content: center;">
      <el-pagination
        v-if="totalPages > 1"
        background
        :page-size="pageSize"
        :page-count="totalPages"
        layout="prev, pager, next"
        :total="totalCount"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { realeseType } from '@/utilities/enums';
import {
  ElMessage,
  ElLoading,
} from 'element-plus';
// import { Catalog, Delete, Pen } from '@/assets/icons/index.js';
export default {
  name: 'PromotionList',
  data: () => ({
    realeseType,
    loading: false,
    releases: [],
    selectedRelease: null,
    promotionModal: false,
    pageNumber: 1,
    totalPages: 0,
    totalCount: 0,
    pageSize: 10,
  }),
  async mounted() {
    this.getReleaseList();
  },
  methods: {
    pageChange(page) {
      this.pageNumber = page;
      this.getReleaseList();
    },
    copy(release) {
      navigator.clipboard.writeText(`${window.location.host}/promotion-link/${release.id}`);
      ElMessage({ message: 'Ссылка успешно скопирована', type: 'success' });
    },
    async getReleaseList() {
      const res = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      this.loading = true;
      const loading = ElLoading.service({
        lock: true,
        text: 'Загрузка',
        background: 'rgba(0, 0, 0, 0)',
        target: '#loader',
      });
      const { data } = await this.$axios.post('/press-release/get-press-releases-by-status', res);
      const {
        pageNumber, totalPages, totalCount, items,
      } = data.items;
      this.pageNumber = pageNumber;
      this.totalPages = totalPages;
      this.totalCount = totalCount;
      this.releases = items;

      loading.close();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
  @media screen and (max-width: 1500px) {
    .promotion-card {
      flex-basis:100%!important;
      max-width:100%!important;
    }
  }

  .promotion{
      &__header{
        width:27.77777777777778em;
        margin-bottom: 1em;
        border-bottom: 2px solid $whiteOpacity;
      }
  }

  .promotion-card{

    transition: 0.3s;
    padding:0.5555555555555556em;
    flex-basis:50%;
    max-width:50%;

    &__btn{
      cursor: pointer;
      transition: 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      width:2em;
      height:2em;
      border-radius: 50%;

      font-weight:500;
      color:$primaryBlue;

      &:hover{
        background-color: rgba(255,255,255,.2);
      }
    }

    &:hover{
      transform: scale(1.01)
    }

    &__wrapper{
      cursor: pointer;
      border: 1px solid $whiteOpacity20;
      overflow: hidden;
      border-radius: 1.9444444444444444em;
      position: relative;
      display: flex;
    }

    &__content{
      width:calc(100% - 15.555555555555555em);
      justify-content: space-around;
      padding: 2.7777777777777777em 1.9444444444444444em ;
      display: flex;
      flex-direction: column;
    }

    &__name{
      font-size: 1.5em;
      font-weight: 500;
    }

    &__artist{
      font-size: 1em;
      font-weight: 500;
    }

    &__type{
      font-size: 1em;
      font-weight: 500;
      color:$subGrey1;
    }

    .el-image{
      border-radius: 1em;
      width:12em;
      height:12em;
      padding: 0;
      margin: 0;

      .image-slot{

        background-color: #151515;
        width:100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
</style>
